<template>
  <v-dialog
    v-model="dialog"
    class="overflow-visible"
    overlay-color="primary"
    overlay-opacity="0.9"
    no-click-animation
    max-width="750"
    scrollable
  >
    <close-button
      @click="closeDialog()"
      overflow
    />
    
    <v-card>
      <v-card-title class="blue-grey lighten-5 justify-center">
        <v-text-field
          v-model="search"
          @input="startSearch()"
          @click.clear="foods = []"
          class="mb-2"
          label="Type food name"
          hide-details
          clearable
          rounded
          solo
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-5">
        <v-alert type="info" :value="!!noResult">
          {{ noResult }}
        </v-alert>
        
        <preloader v-if="searching" text="Please wait..." />

        <v-simple-table v-if="!searching && foods.length">
          <template v-slot:default>
            <tbody>
              <tr v-for="food in refined" :key="food.objectId">
                <td class="pl-0 py-2">
                  {{ food.name }}
                </td>
                <td width="30%" class="text-right pr-0">
                  <v-btn @click="selectFood(food)" depressed x-small color="accent">Select</v-btn>

                  <v-tooltip left>
                    <template #activator="{ on }">
                      <v-btn @click="showReportDialog(food)" class="ml-1" v-on="on" icon x-small>
                        <v-icon>mdi-alert-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span class="caption">Report food</span>
                  </v-tooltip> 
                  
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="reportDialog"
      max-width="450"
    >
      <close-button @click="reportDialog = false" overflow />

      <v-card>
        <v-card-title primary-title>
          Report Food
        </v-card-title>
        <v-card-text>
          <v-form ref="reportForm">
            <v-textarea
              v-model="reportMessage"
              label="Write your message"
              :rules="[rules.required]"
              outlined
            ></v-textarea>

            <v-btn 
              @click="reportForm()"
              :loading="status.reporting"
              color="primary"
            >Submit</v-btn>
          </v-form>
        </v-card-text>
      </v-card> 
    </v-dialog>
  </v-dialog>
</template>

<script>
const client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, 'b01a34dc50c42c2e6fe90f9ec6d376ad')

import rules from '@/rules'
import db from '@/firebase/init'
import { debounce, orderBy } from 'lodash'
import algoliasearch from 'algoliasearch'
import { mapState, mapActions } from 'vuex'
const index = client.initIndex('foods')

export default {
  name: 'FindIngredients',


  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      foods: [],
      search: null,
      dialog: false,
      toReport: null,
      noResult: false,
      searching: false,
      reportDialog: false,
      reportMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.foods.status,
      ingredients: (state) => state.recipe.ingredients,
    }),

    refined: function () {
      return orderBy(this.foods, (food) => food.name.length, 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('recipe', [
      'getFood'
    ]),
    
    ...mapActions('foods', [
      'reportFood'
    ]),

    /*------------------------------------------------------------------------------
     * START SEARCH
     *----------------------------------------------------------------------------*/
    startSearch: debounce(function () {
      this.searching = true
      this.noResult = false
      
      if (this.search && this.search.length > 2) {
        index.search(`${this.search} -by`)
        .then(({ hits }) => {
          if (!hits.length) {
            this.noResult = 'Please wait while we are importing food from other database source.'
            this.searching = false
          }
          else {
            var ids = hits.map(hit => hit.objectID)
            var promises = []

            ids.forEach(id => {
              promises.push(db.collection('foods').doc(id).get())
            })

            Promise.all(promises)
            .then(docs => {
              if (docs.length) {
                docs.forEach(doc => {
                  if (doc.exists && doc.data().status == 'published') {
                    let food = doc.data()
                    food.id = doc.id
                    food.ref = doc.ref
                    this.foods.push(food)
                  }
                })
              }
            })

            this.searching = false
          }
        })
        .catch(error => {
          console.log(error)
          this.noResult = false
          this.searching = false
        })
      }
      else {
        this.searching = false
        this.foods = []
      }
    }, 500),

    /*------------------------------------------------------------------------------
     * SELECT FOOD
     *----------------------------------------------------------------------------*/
    selectFood(food) {
      this.closeDialog()
      this.getFood(food)
    },

    /*------------------------------------------------------------------------------
     * CLOSE DIALOG
     *----------------------------------------------------------------------------*/
    closeDialog() {
      this.search = null
      this.foods = []
      this.dialog = false
    },

    /*------------------------------------------------------------------------------
     * SHOW REPORT DIALOG
     *----------------------------------------------------------------------------*/
    showReportDialog(food) {
      this.toReport = food
      this.reportDialog = true
    },

    /*------------------------------------------------------------------------------
     * VALIDATE REPORT
     *----------------------------------------------------------------------------*/
    reportForm() {
      if (this.$refs.reportForm.validate()) {
        Promise.resolve(this.reportFood({
          food: this.toReport,
          message: this.reportMessage
        }))
        .then(() => {
          this.reportDialog = false
        })
      }
    }
  },
}

</script>